.social-list {
  display: flex;
  gap: 1px;

  list-style: none;
}

.social-list__link {
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;

  background-color: rgba(white,0.1);

    &:hover {
      
      svg {
        fill: white;
      }

    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: $color-brass;
    }

}

.pg-footer__contact-info {
  
  .social-list__link:hover svg {
    fill: $color-navy;
  }

}