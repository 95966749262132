// Brand colors

// Brand pallette

$color-navy: #202D44;
$color-grey-light: #D6E0DD;
$color-blue-dark: #262D34;
$color-brass: #D6B065;

// Validation

$color-error: #DB0000;
$color-success: #55a630;

// Typography

@font-face {
  font-family: 'BebasNeueRegular';
  src: url('fonts/BebasNeueRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BebasNeueBook';
  src: url('fonts/BebasNeueBook.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BebasNeueLight';
  src: url('fonts/BebasNeueLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('fonts/Oswald-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OswaldExtraLight';
  src: url('fonts/Oswald-ExtraLight.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$bebas-regular: 'BebasNeueRegular', sans-serif;
$bebas-book: 'BebasNeueBook', sans-serif;
$oswald-regular: 'Oswald', sans-serif;
$oswald-light: 'OswaldExtraLight', sans-serif;

$kerning-md: -0.15rem;
$kerning-sm: -0.1rem;

$line-height-md: 1.5;
$line-height-sm: 1.2;

$font-size-xl: 5.1rem;
$font-size-lg: 4.2rem;
$font-size-md: 3.3rem;
$font-size-sm: 2.4rem;
$font-size-xs: 2.1rem;
$font-size-xxs: 1.5rem;

$font-mobile-scale: 0.75;

// UI styles

$padding-global: 6rem;

$padding-xxl: calc($padding-global*2);
$padding-xl: calc($padding-global*1.5);
$padding-lg: $padding-global;
$padding-md: calc($padding-global/2);
$padding-sm: calc($padding-global/4);

// Box shadow

$box-shadow: 0 4px 6px rgba(0,0,0,0.1);
$box-shadow-hover: 0 4px 6px rgba(0,0,0,0.2);

// Border radius

$border-radius-lg: 2rem;
$border-radius-md: 1rem;
$border-radius-sm: 0.5rem;

// Transitions

$transition-sm: 0.1s all ease-out;