/*!
Theme Name: Chef's Counter
Theme URI: 
Author: Abbas Arezoo
Author URI: http://www.abbasarezoo.com/
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/
@font-face {
  font-family: "BebasNeueRegular";
  src: url("fonts/BebasNeueRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "BebasNeueBook";
  src: url("fonts/BebasNeueBook.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "BebasNeueLight";
  src: url("fonts/BebasNeueLight.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Oswald";
  src: url("fonts/Oswald-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OswaldExtraLight";
  src: url("fonts/Oswald-ExtraLight.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-size: 1.575rem;
  color: #262D34;
  background-color: white;
}
@media (min-width: 1280px) {
  body {
    font-size: 2.1rem;
  }
}

[id] {
  scroll-margin-top: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

p {
  line-height: 1.5;
}

strong {
  font-weight: normal;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  font-size: 62.5%;
  /* 1rem = 10px */
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=date],
input[type=month],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=week],
input[type=number],
input[type=search],
input[type=tel],
input[type=color],
input[type=submit],
textarea,
button {
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

select {
  font-size: inherit;
}

button, input[type=submit] {
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.screen-reader-text {
  display: none;
}

.align-center {
  text-align: center;
}

.bg-navy {
  background-color: #202D44;
}

.bg-blue {
  background-color: #262D34;
}

.bg-brass {
  background-color: #D6B065;
}

.bg-marble-navy {
  background: url("img/bg-marble-navy.jpg") center center no-repeat;
  background-size: cover;
}

.bg-marble-blue, .cookery-class-modal {
  background: url("img/bg-marble-blue.jpg") center center no-repeat;
  background-size: cover;
}

.bg-marble-white {
  background: url("img/bg-marble-white.jpg") center center no-repeat;
  background-size: cover;
}

.video-embed {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .video-embed:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  .video-embed:after {
    display: block;
    content: "";
    clear: both;
  }
}

.video-embed iframe {
  width: 100%;
  height: 100%;
}

.flex-img {
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex-img {
    flex-shrink: 0;
  }
}

.outer {
  position: relative;
}

.inner, .main-nav {
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
}
@media (min-width: 1280px) {
  .inner, .main-nav {
    padding: 0 6rem;
  }
}

.inner.inner--full, .inner--full.main-nav {
  padding: 0;
}

@media (min-width: 768px) {
  .inner--sm {
    width: 768px;
  }
}

@media (min-width: 1024px) {
  .inner--md {
    width: 1024px;
  }
}

@media (min-width: 1280px) {
  .inner--lg, .main-nav {
    width: 1280px;
  }
}

@media (min-width: 1600px) {
  .inner--xl {
    width: 1600px;
  }
}

@media (min-width: 1800px) {
  .inner--xxl {
    width: 1800px;
  }
}

.site-wrapper {
  position: relative;
  z-index: 1;
}

.main {
  position: relative;
  z-index: 1;
}

.modal-is-open {
  height: 100vh;
  overflow: hidden;
}

.mt-xs {
  margin-top: 0.5rem;
}
@media (min-width: 1024px) {
  .mt-xs {
    margin-top: 1rem;
  }
}

.mt-sm {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .mt-sm {
    margin-top: 1.5rem;
  }
}

.mt-md {
  margin-top: 1.5rem;
}
@media (min-width: 1024px) {
  .mt-md {
    margin-top: 3rem;
  }
}

.mt-lg {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .mt-lg {
    margin-top: 6rem;
  }
}

.mt-xl {
  margin-top: 6rem;
}
@media (min-width: 1024px) {
  .mt-xl {
    margin-top: 9rem;
  }
}

.mb-xs {
  margin-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .mb-xs {
    margin-bottom: 1rem;
  }
}

.mb-sm {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .mb-sm {
    margin-bottom: 1.5rem;
  }
}

.mb-md {
  margin-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .mb-md {
    margin-bottom: 3rem;
  }
}

.mb-lg {
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .mb-lg {
    margin-bottom: 6rem;
  }
}

.mb-xl {
  margin-bottom: 6rem;
}
@media (min-width: 1024px) {
  .mb-xl {
    margin-bottom: 9rem;
  }
}

.mtb-xs {
  margin: 0.5rem 0;
}
@media (min-width: 1024px) {
  .mtb-xs {
    margin: 1rem 0;
  }
}

.mtb-sm {
  margin: 1rem 0;
}
@media (min-width: 1024px) {
  .mtb-sm {
    margin: 1.5rem 0;
  }
}

.mtb-md {
  margin: 1.5rem 0;
}
@media (min-width: 1024px) {
  .mtb-md {
    margin: 3rem 0;
  }
}

.mtb-lg {
  margin: 3rem 0;
}
@media (min-width: 1024px) {
  .mtb-lg {
    margin: 6rem 0;
  }
}

.mtb-xl {
  margin: 6rem 0;
}
@media (min-width: 1024px) {
  .mtb-xl {
    margin: 9rem 0;
  }
}

.pt-xs {
  padding-top: 0.5rem;
}
@media (min-width: 1024px) {
  .pt-xs {
    padding-top: 1rem;
  }
}

.pt-sm {
  padding-top: 1rem;
}
@media (min-width: 1024px) {
  .pt-sm {
    padding-top: 1.5rem;
  }
}

.pt-md {
  padding-top: 1.5rem;
}
@media (min-width: 1024px) {
  .pt-md {
    padding-top: 3rem;
  }
}

.pt-lg {
  padding-top: 3rem;
}
@media (min-width: 1024px) {
  .pt-lg {
    padding-top: 6rem;
  }
}

.pt-xl, .hero__text {
  padding-top: 6rem;
}
@media (min-width: 1024px) {
  .pt-xl, .hero__text {
    padding-top: 9rem;
  }
}

.pt-xxl {
  padding-top: 9rem;
}
@media (min-width: 1024px) {
  .pt-xxl {
    padding-top: 12rem;
  }
}

.pb-xs {
  padding-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .pb-xs {
    padding-bottom: 1rem;
  }
}

.pb-sm {
  padding-bottom: 1rem;
}
@media (min-width: 1024px) {
  .pb-sm {
    padding-bottom: 1.5rem;
  }
}

.pb-md {
  padding-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .pb-md {
    padding-bottom: 3rem;
  }
}

.pb-lg, .hero__text {
  padding-bottom: 3rem;
}
@media (min-width: 1024px) {
  .pb-lg, .hero__text {
    padding-bottom: 6rem;
  }
}

.pb-xl {
  padding-bottom: 6rem;
}
@media (min-width: 1024px) {
  .pb-xl {
    padding-bottom: 9rem;
  }
}

.ptb-xs {
  padding: 0.5rem 0;
}
@media (min-width: 1024px) {
  .ptb-xs {
    padding: 1rem 0;
  }
}

.ptb-sm {
  padding: 1rem 0;
}
@media (min-width: 1024px) {
  .ptb-sm {
    padding: 1.5rem 0;
  }
}

.ptb-md {
  padding: 1.5rem 0;
}
@media (min-width: 1024px) {
  .ptb-md {
    padding: 3rem 0;
  }
}

.ptb-lg, .menu-cta {
  padding: 3rem 0;
}
@media (min-width: 1024px) {
  .ptb-lg, .menu-cta {
    padding: 6rem 0;
  }
}

.ptb-xl {
  padding: 6rem 0;
}
@media (min-width: 1024px) {
  .ptb-xl {
    padding: 9rem 0;
  }
}

.ptb-xxl {
  padding: 9rem 0;
}
@media (min-width: 1024px) {
  .ptb-xxl {
    padding: 12rem 0;
  }
}

.pg-header-outer {
  position: relative;
  z-index: 2;
  padding: 1.5rem 0;
}
@media (min-width: 768px) {
  .pg-header-outer {
    padding: 0;
  }
}

.contact-bar {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .contact-bar {
    justify-content: space-between;
  }
}
.contact-bar .social-list {
  display: none;
}
@media (min-width: 768px) {
  .contact-bar .social-list {
    display: flex;
  }
}

.contact-bar__list {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  list-style: none;
}
.contact-bar__list li {
  position: relative;
}
.contact-bar__list a {
  color: white;
  text-decoration: none;
}
.contact-bar__list a:hover {
  text-decoration: underline;
}
.contact-bar__list li + li {
  margin: 0 0 0 1rem;
  padding: 0 0 0 1rem;
}
.contact-bar__list li + li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: white;
}

.pg-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 1.5rem;
  background: white;
}
@media (min-width: 768px) {
  .pg-header {
    justify-content: center;
    height: 9.25rem;
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .pg-header {
    height: 12.5rem;
  }
}

.pg-header__logo {
  position: relative;
  display: block;
  width: 8rem;
  height: 8rem;
  margin: 0 0 3rem;
  background: url("img/bg-marble-navy.jpg") center center no-repeat;
}
@media (min-width: 768px) {
  .pg-header__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 12rem;
    height: 12rem;
  }
}
@media (min-width: 1280px) {
  .pg-header__logo {
    width: 16rem;
    height: 16rem;
  }
}
.pg-header__logo svg {
  position: absolute;
  bottom: 1rem;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
}

.main-nav {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (min-width: 768px) {
  .main-nav {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.main-nav__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  list-style: none;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 2.1rem;
}
@media (min-width: 768px) {
  .main-nav__list {
    position: relative;
    z-index: 1;
    gap: 3rem;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .main-nav__list {
    gap: 6rem;
    font-size: 2.4rem;
  }
}
@media (min-width: 768px) {
  .main-nav__list:first-child {
    justify-content: flex-end;
    padding: 0 8rem 0 0;
  }
}
@media (min-width: 1280px) {
  .main-nav__list:first-child {
    padding: 0 12rem 0 0;
  }
}
@media (min-width: 768px) {
  .main-nav__list:last-child {
    justify-content: flex-start;
    padding: 0 0 0 8rem;
  }
}
@media (min-width: 1280px) {
  .main-nav__list:last-child {
    padding: 0 0 0 12rem;
  }
}
.main-nav__list a {
  color: #262D34;
  text-decoration: none;
}
.main-nav__list a:hover {
  text-decoration: underline;
}

.pg-footer {
  display: grid;
  grid-gap: 3rem;
  padding: 0 0 3rem;
  margin: 0 0 3rem;
  border-bottom: 1px solid #D6B065;
}
@media (min-width: 1280px) {
  .pg-footer {
    padding: 0 0 6rem;
    margin: 0 0 6rem;
    grid-template-columns: 3fr 2fr;
  }
}
.pg-footer .title, .pg-footer .occasions li, .occasions .pg-footer li {
  text-decoration: underline;
}

.pg-footer__address-info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 3rem;
  border-bottom: 1px solid #D6B065;
}
@media (min-width: 1280px) {
  .pg-footer__address-info {
    display: grid;
    grid-gap: 6rem;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    border-bottom: none;
    border-right: 1px solid #D6B065;
  }
}

.pg-footer__logo-outer {
  width: 100%;
  margin: 0 0 3rem;
}
@media (min-width: 1280px) {
  .pg-footer__logo-outer {
    margin: 0;
  }
}

.pg-footer__logo {
  display: block;
  width: 10rem;
  height: auto;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .pg-footer__logo {
    margin: 0;
  }
}

.pg-footer__address,
.pg-footer__hours {
  width: 100%;
  text-align: center;
}
@media (min-width: 650px) {
  .pg-footer__address,
.pg-footer__hours {
    width: 50%;
    padding: 0 3rem;
  }
}
@media (min-width: 1280px) {
  .pg-footer__address,
.pg-footer__hours {
    width: auto;
    padding: 0;
    text-align: left;
  }
}

.pg-footer__address {
  margin: 0 0 3rem;
}
@media (min-width: 650px) {
  .pg-footer__address {
    margin: 0;
  }
}

.pg-footer__text,
.pg-footer__nav-list {
  font-family: "OswaldExtraLight", sans-serif;
  font-size: 1.5rem;
}

.pg-footer__nav-outer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 1280px) {
  .pg-footer__nav-outer {
    display: grid;
    grid-gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.pg-footer__nav,
.pg-footer__contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}
@media (min-width: 650px) {
  .pg-footer__nav,
.pg-footer__contact-info {
    width: 50%;
    padding: 0 3rem;
  }
}
@media (min-width: 1280px) {
  .pg-footer__nav,
.pg-footer__contact-info {
    align-items: flex-start;
    width: auto;
    padding: 0;
    text-align: left;
  }
}

.pg-footer__nav {
  margin: 0 0 3rem;
}
@media (min-width: 650px) {
  .pg-footer__nav {
    margin: 0;
  }
}

.pg-footer__nav-list {
  display: grid;
  grid-gap: 0.5rem;
  text-align: center;
  list-style-type: none;
}
@media (min-width: 1280px) {
  .pg-footer__nav-list {
    text-align: left;
  }
}
.pg-footer__nav-list a {
  color: #202D44;
  text-decoration: none;
}
.pg-footer__nav-list a:hover {
  text-decoration: underline;
}

.signoff {
  font-family: "OswaldExtraLight", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}
@media (min-width: 650px) {
  .signoff {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.signoff__nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .signoff__nav {
    flex-direction: row;
    justify-content: flex-end;
    gap: 3rem;
  }
}
.signoff__nav a {
  color: #202D44;
  text-decoration: none;
}
.signoff__nav a:hover {
  text-decoration: underline;
}

.title, .occasions li {
  font-family: "BebasNeueBook", sans-serif;
  line-height: 1.2;
  text-wrap: balance;
}
.title strong, .occasions li strong {
  font-family: "BebasNeueRegular", sans-serif;
}
.title a, .occasions li a {
  color: inherit;
}

.sub-title {
  font-family: "OswaldExtraLight", sans-serif;
  line-height: 1.2;
}
.sub-title a {
  color: inherit;
}

.title--xl {
  font-size: 3.825rem;
  text-decoration: underline;
}
@media (min-width: 1280px) {
  .title--xl {
    font-size: 5.1rem;
  }
}

.title--lg {
  font-size: 3.15rem;
}
@media (min-width: 1280px) {
  .title--lg {
    font-size: 4.2rem;
  }
}

.title--md, .occasions li,
.sub-title--lg {
  font-size: 2.475rem;
}
@media (min-width: 1280px) {
  .title--md, .occasions li,
.sub-title--lg {
    font-size: 3.3rem;
  }
}

.title--sm,
.sub-title--md {
  font-size: 2.1rem;
}

.title--border-brass {
  position: relative;
  padding: 0 0 1rem;
  text-align: center;
}
.title--border-brass:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 50%;
  height: 2px;
  background-color: #D6B065;
}

.supporting-text {
  font-size: 1.8rem;
  line-height: 1.5;
}
@media (min-width: 1280px) {
  .supporting-text {
    font-size: 2.4rem;
  }
}

.feed-text {
  font-size: 1.5rem;
}

.text-white {
  color: white;
}

.text-blue-dark {
  color: #262D34;
}

.text-navy {
  color: #202D44;
}

.text-brass {
  color: #D6B065;
}

.btn {
  display: block;
  padding: 1rem 6rem;
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 2.1rem;
  text-decoration: none;
  letter-spacing: 0.05em;
  text-align: center;
  border: 2px solid;
}

button.btn,
input[type=submit].btn {
  font-family: "BebasNeueRegular", sans-serif;
  background: transparent;
}
button.btn:hover,
input[type=submit].btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn--white {
  color: white;
}
.btn--white:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.btn--white-brass {
  color: white;
  border-color: #D6B065;
}
.btn--white-brass:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  list-style: none;
}
.btn-group li {
  width: 100%;
}
@media (min-width: 650px) {
  .btn-group li {
    width: auto;
  }
}

.home-hero__content .btn-group {
  justify-content: center;
}

.social-list {
  display: flex;
  gap: 1px;
  list-style: none;
}

.social-list__link {
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.1);
}
.social-list__link:hover svg {
  fill: white;
}
.social-list__link svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #D6B065;
}

.pg-footer__contact-info .social-list__link:hover svg {
  fill: #202D44;
}

.home-hero {
  position: relative;
  background-color: #202D44;
}
@media (min-width: 768px) {
  .home-hero {
    height: 75vh;
  }
}

.home-hero__img {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .home-hero__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    opacity: 0.5;
    object-fit: cover;
  }
}

.home-hero__content {
  padding: 6rem 3rem;
}
@media (min-width: 768px) {
  .home-hero__content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    height: 100%;
  }
}
@media (min-width: 1280px) {
  .home-hero__content {
    width: 768px;
  }
}

.home-hero__text {
  font-family: "OswaldExtraLight", sans-serif;
  font-size: 2.475rem;
  line-height: 1.5;
  color: white;
}
@media (min-width: 1280px) {
  .home-hero__text {
    font-size: 3.3rem;
  }
}

.std-content {
  width: 100%;
}
.std-content > * + * {
  margin: 3rem 0 0;
}
.std-content h2, .std-content h3, .std-content h4, .std-content h5, .std-content h6 {
  font-family: "BebasNeueBook", sans-serif;
  line-height: 1.2;
}
.std-content p a, .std-content ul a, .std-content ol a {
  color: black;
  text-decoration: underline;
}
.std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
  opacity: 0.75;
}
.std-content p > strong, .std-content ul > strong, .std-content ol > strong {
  color: black;
}
.std-content p > em, .std-content ul > em, .std-content ol > em {
  font-style: italic;
}
.std-content p {
  line-height: 1.5;
}
.std-content sup {
  font-size: 75%;
}
.std-content ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}
.std-content ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
  counter-reset: item;
}
.std-content ul ul {
  margin: 1rem 0 0 3rem;
  list-style-type: circle;
}
.std-content li {
  line-height: 1.5;
}
.std-content li + li {
  margin-top: 1rem;
}
.std-content img {
  display: block;
  width: 100%;
  height: auto;
  margin: 6rem auto;
}
.std-content a {
  color: inherit;
  text-decoration: underline;
}
.std-content address {
  font-style: normal;
}

.std-content img.alignleft,
.std-content img.alignright {
  width: 50%;
  margin: 6rem auto;
}
@media (min-width: 450px) {
  .std-content img.alignleft,
.std-content img.alignright {
    width: 20%;
    clear: both;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }
}
@media (min-width: 1280px) {
  .std-content img.alignleft,
.std-content img.alignright {
    width: 25%;
  }
}
@media (min-width: 450px) {
  .std-content img.alignleft {
    float: left;
    margin-right: 6rem;
  }
}
@media (min-width: 450px) {
  .std-content img.alignright {
    float: right;
    margin-left: 6rem;
  }
}
.std-content img.alignnone {
  clear: both;
}
.std-content .wp-caption {
  width: 100% !important;
  height: auto;
  margin: 6rem auto;
}
.std-content .wp-caption img {
  margin: 0;
}
.std-content p.wp-caption-text {
  margin: 0 auto;
  padding: 0.5rem;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
}
.std-content .video-embed {
  clear: both;
  margin: 6rem 0;
}

.menu-cta {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (min-width: 1280px) {
  .menu-cta {
    gap: 6rem;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: left;
  }
}
.menu-cta .btn {
  width: 100%;
}
@media (min-width: 650px) {
  .menu-cta .btn {
    width: auto;
  }
}
@media (min-width: 1280px) {
  .menu-cta .btn {
    flex-shrink: 0;
  }
}

.contact-cta {
  position: relative;
  background-color: #202D44;
}

.contact-cta__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
}

.contact-cta__text {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hero {
  position: relative;
  background-color: #202D44;
}

.hero__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
}

.hero__text {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-marble-blue .title, .cookery-class-modal .title, .bg-marble-blue .occasions li, .cookery-class-modal .occasions li, .occasions .bg-marble-blue li, .occasions .cookery-class-modal li,
.bg-marble-navy .title,
.bg-marble-navy .occasions li,
.occasions .bg-marble-navy li {
  color: white;
}
.bg-marble-blue .std-content, .cookery-class-modal .std-content,
.bg-marble-navy .std-content {
  color: white;
}

@media (min-width: 768px) {
  .title-text-block {
    display: flex;
  }
}
@media (min-width: 768px) {
  .title-text-block .title, .title-text-block .occasions li, .occasions .title-text-block li {
    width: 33.3333333333%;
  }
}
@media (min-width: 1280px) {
  .title-text-block .title, .title-text-block .occasions li, .occasions .title-text-block li {
    width: 25%;
  }
}
.title-text-block .std-content {
  margin: 3rem 0 0;
  padding: 3rem 0 0;
  border-top: 2px solid #D6B065;
}
@media (min-width: 768px) {
  .title-text-block .std-content {
    margin: 0 0 0 6rem;
    padding: 0 0 0 6rem;
    border-left: 2px solid #D6B065;
    border-top: none;
  }
}

.features-outer {
  position: relative;
}
@media (min-width: 1024px) {
  .features-outer {
    display: flex;
    justify-content: flex-end;
  }
}

.features-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: cover;
}

.features {
  position: relative;
  z-index: 2;
  padding: 3rem;
  background-color: rgba(32, 45, 68, 0.85);
}
@media (min-width: 1024px) {
  .features {
    width: 66.6666666667%;
    padding: 6rem;
  }
}
@media (min-width: 1280px) {
  .features {
    padding: 9rem 6rem;
  }
}

.features__list {
  display: grid;
  grid-gap: 6rem;
  list-style-type: none;
}
@media (min-width: 1024px) {
  .features__list {
    max-width: 768px;
  }
}
@media (min-width: 1280px) {
  .features__list {
    grid-gap: 9rem;
  }
}

.features__list__item {
  padding: 0 0 0 3rem;
  border-left: 2px solid #D6B065;
}
@media (min-width: 1280px) {
  .features__list__item {
    padding: 0 0 0 6rem;
  }
}
.features__list__item .title, .features__list__item .occasions li, .occasions .features__list__item li {
  text-decoration: underline;
}

.occasions {
  color: white;
}
.occasions ul {
  list-style-type: none;
}
@media (min-width: 650px) {
  .occasions ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.occasions li {
  padding: 0.5rem 0 0.5rem 3rem;
  border-left: 2px solid #D6B065;
}
@media (min-width: 1280px) {
  .occasions li {
    padding: 0.5rem 0 0.5rem 6rem;
  }
}

.cookery-classes {
  display: grid;
  grid-gap: 3rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .cookery-classes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .cookery-classes {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cookery-class__toggle {
  position: relative;
  width: 100%;
  height: 25rem;
  border: none;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .cookery-class__toggle {
    height: 35rem;
  }
}
.cookery-class__toggle:hover .cookery-class__info {
  background-color: #202D44;
}
.cookery-class__toggle:hover .cookery-class__img {
  transform: scale(1.1);
}

.cookery-class__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.1s all ease-out;
}

.cookery-class__info {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(32, 45, 68, 0.75);
  transition: 0.1s all ease-out;
}

.cookery-class__info-title {
  position: relative;
  padding: 0 0 0 1.5rem;
}
.cookery-class__info-title:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 2px;
  height: 100%;
  background-color: #D6B065;
}

.cookery-class-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 45, 68, 0.85);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}
.cookery-class-overlay.is-open {
  visibility: visible;
  opacity: 1;
  overflow: auto;
}
@media (min-width: 1024px) {
  .cookery-class-overlay {
    display: grid;
    place-items: center;
  }
}

.cookery-class-modal {
  display: none;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 3rem;
  color: white;
}
.cookery-class-modal.is-visible {
  display: block;
}
@media (min-width: 768px) {
  .cookery-class-modal.is-visible {
    display: grid;
  }
}
@media (min-width: 768px) {
  .cookery-class-modal {
    grid-template-columns: 1fr 2.5fr;
    grid-gap: 3rem;
  }
}
@media (min-width: 1024px) {
  .cookery-class-modal {
    width: 75%;
    min-height: 0;
  }
}
.cookery-class-modal .std-content {
  font-size: 1.5rem;
}
.cookery-class-modal .std-content > * + * {
  margin: 1.5rem 0 0;
}
.cookery-class-modal .std-content p {
  font-size: 1.5rem;
}
.cookery-class-modal .std-content a {
  color: white;
}
.cookery-class-modal .std-content strong {
  color: #D6B065;
}

.cookery-class-modal__img {
  display: none;
}
@media (min-width: 768px) {
  .cookery-class-modal__img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
  }
}

.cookery-class-modal__content {
  position: relative;
  z-index: 1;
}

.close-modal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  background: none;
  fill: #D6B065;
}
.close-modal:hover {
  fill: white;
}

.contact-panel {
  padding: 3rem;
  background-color: #202D44;
}
@media (min-width: 768px) {
  .contact-panel {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 6rem;
    padding: 6rem;
  }
}
@media (min-width: 1280px) {
  .contact-panel {
    grid-template-columns: repeat(2, 1fr);
  }
}

.contact-panel__tel-social {
  margin: 0 0 3rem;
}
@media (min-width: 1024px) {
  .contact-panel__tel-social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .contact-panel__tel-social .title, .contact-panel__tel-social .occasions li, .occasions .contact-panel__tel-social li {
    margin: 0 3rem 0 0;
  }
}

.form-row + .form-row {
  margin: 1.5rem 0 0;
}

.form-row + [data-class=wpcf7cf_group] {
  margin: 1.5rem 0;
}

[data-class=wpcf7cf_group] + .form-row {
  margin: 1.5rem 0;
}

.form-row__cols {
  display: grid;
  grid-gap: 3rem;
}
@media (min-width: 768px) {
  .form-row__cols {
    grid-template-columns: repeat(2, 1fr);
    align-items: end;
  }
}

.form-row__label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  color: #D6B065;
  line-height: 1.5;
}

.form-row input[type=text],
.form-row input[type=number],
.form-row input[type=url],
.form-row input[type=password],
.form-row input[type=email],
.form-row input[type=tel],
.form-row input[type=date],
.form-row select {
  display: block;
  width: 100%;
  height: 5rem;
  padding: 0 1rem;
  border: none;
  font-size: 2.1rem;
  font-family: "Oswald", sans-serif;
}
.form-row input[type=text]:focus,
.form-row input[type=number]:focus,
.form-row input[type=url]:focus,
.form-row input[type=password]:focus,
.form-row input[type=email]:focus,
.form-row input[type=tel]:focus,
.form-row input[type=date]:focus,
.form-row select:focus {
  outline: none;
  border: 2px solid #D6B065;
}

.form-row input[type=date] {
  text-transform: uppercase;
}

.form-row textarea {
  display: block;
  width: 100%;
  height: 20rem;
  padding: 1rem;
  border: none;
  font-size: 2.1rem;
  font-family: "Oswald", sans-serif;
}
.form-row textarea:focus {
  outline: none;
  border: 2px solid #D6B065;
}

.form-row__gdpr {
  padding: 3rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
}
.form-row__gdpr a {
  color: #202D44;
}
.form-row__gdpr a:hover {
  color: black;
}

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid {
  margin: 0 0 1rem;
}

span.wpcf7-not-valid {
  display: block;
  margin: 0 0 1rem;
}

.wpcf7-not-valid-tip {
  display: block;
  padding: 1rem;
  background-color: #DB0000;
  color: white;
}

.wpcf7-response-output {
  display: block;
  padding: 1rem;
  border: none;
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: 3rem 0 0;
  background-color: #DB0000;
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: 3rem 0 0;
  background-color: #55a630;
}

.wpcf7-radio {
  display: block;
}

.wpcf7-list-item {
  display: block;
}
.wpcf7-list-item input[type=radio],
.wpcf7-list-item input[type=checkbox] {
  margin: 0 1rem 0 0;
}

.wpcf7-list-item + .wpcf7-list-item {
  margin: 1.5rem 0 0;
}

.wpcf7-acceptance label {
  display: flex;
}

.wpcf7-acceptance input[type=checkbox] {
  margin: 0.5rem 1rem 0 0;
}

.wpcf7-submit {
  font-family: "BebasNeueRegular", sans-serif;
}