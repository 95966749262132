.contact-cta {
  position: relative;
  background-color: $color-navy;

}

.contact-cta__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  object-fit: cover;
}

.contact-cta__text {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}