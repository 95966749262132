.contact-panel {
  padding: $padding-md;
  background-color: $color-navy;

    @include mq(screen-sm) {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      grid-gap: $padding-lg;

      padding: $padding-lg;
    }

    @include mq(screen-lg) {
      grid-template-columns: repeat(2, 1fr);
    }

}

.contact-panel__tel-social {
  margin: 0 0 $padding-md;
  
  @include mq(screen-md) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }

  .title {
    
    @include mq(screen-md) {
      margin: 0 $padding-md 0 0;
    }

  }

}