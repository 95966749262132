.bg-marble-blue,
.bg-marble-navy {
  
  .title {
    color: white;
  }

  .std-content {
    color: white;
  }

}

.title-text-block {

  @include mq(screen-sm) {
    display: flex;
  }

  .title {
    
    @include mq(screen-sm) {
      width: percentage(1/3);
    }

    @include mq(screen-lg) {
      width: 25%;
    }

  }
  
  .std-content {
    margin: $padding-md 0 0;
    padding: $padding-md 0 0;

    border-top: 2px solid $color-brass;

      @include mq(screen-sm) {
        margin: 0 0 0 $padding-lg;
        padding: 0 0 0 $padding-lg;

        border-left: 2px solid $color-brass;
        border-top: none;
      }

  }

}