.features-outer {
  position: relative;

    @include mq(screen-md) {
      display: flex;
      justify-content: flex-end;
    }

}

.features-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;

  object-fit: cover;
}

.features {
  position: relative;
  z-index: 2;

  padding: $padding-md;

  background-color: rgba($color-navy, 0.85);

    @include mq(screen-md) {
      width: percentage(2/3);
      padding: $padding-lg;
    }

    @include mq(screen-lg) {
      padding: $padding-xl $padding-lg;
    }

}

.features__list {
  display: grid;
  grid-gap: $padding-lg;

  list-style-type: none;

    @include mq(screen-md) {
      max-width: $screen-sm;
    }

    @include mq(screen-lg) {
      grid-gap: $padding-xl;
    }

}

.features__list__item {
  padding: 0 0 0 $padding-md;

  border-left: 2px solid $color-brass;

    @include mq(screen-lg) {
      padding: 0 0 0 $padding-lg;
    }

    .title {
      text-decoration: underline;
    }

}

.occasions {
  color: white;

    ul {
      list-style-type: none;

        @include mq(screen-xs) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
    }
    
    li {
      padding: 0.5rem 0 0.5rem $padding-md;

      border-left: 2px solid $color-brass;

      @extend .title;
      @extend .title--md;

        @include mq(screen-lg) {
          padding: 0.5rem 0 0.5rem $padding-lg;
        }

    }

}