.hero {
  position: relative;
  background-color: $color-navy;

}

.hero__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  object-fit: cover;
}

.hero__text {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  @extend .pt-xl;
  @extend .pb-lg;
}