// Titles

.title {
  font-family: $bebas-book;
  line-height: $line-height-sm;
  text-wrap: balance;

    strong {
      font-family: $bebas-regular;
    }

    a {
      color: inherit;
    }

}

.sub-title {
  font-family: $oswald-light;
  line-height: $line-height-sm;

    a {
      color: inherit;
    }

}

.title--xl {
  font-size: calc($font-size-xl * $font-mobile-scale);
  text-decoration: underline;

    @include mq(screen-lg) {
      font-size: $font-size-xl;
    }

}

.title--lg {
  font-size: calc($font-size-lg * $font-mobile-scale);

    @include mq(screen-lg) {
      font-size: $font-size-lg;
    }
}

.title--md,
.sub-title--lg {
  font-size: calc($font-size-md * $font-mobile-scale);

    @include mq(screen-lg) {
      font-size: $font-size-md;
    }

}

.title--sm,
.sub-title--md {
  font-size: $font-size-xs;
}

// Border

.title--border-brass {
  position: relative;
  padding: 0 0 1rem;

  text-align: center;

    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      width: 50%;
      height: 2px;

      background-color: $color-brass;
    }
}

// Text

.supporting-text {
  font-size: calc($font-size-sm * $font-mobile-scale);
  line-height: $line-height-md;

    @include mq(screen-lg) {
      font-size: $font-size-sm;
    }

}

.feed-text {
  font-size: $font-size-xxs;
}

// Colours

.text-white {
  color: white;
}

.text-blue-dark {
  color: $color-blue-dark;
}

.text-navy {
  color: $color-navy;
}

.text-brass {
  color: $color-brass;
}