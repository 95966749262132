// =================================================
// LAYOUT STYLES
// =================================================

.outer {
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 $padding-md;

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

}

.inner.inner--full {
  padding: 0;
}

.inner--sm {

  @include mq(screen-sm) {
    width: $screen-sm;
  }

}

.inner--md {

  @include mq(screen-md) {
    width: $screen-md;
  }

}

.inner--lg {

  @include mq(screen-lg) {
    width: $screen-lg;
  }

}

.inner--xl {

  @include mq(screen-xl) {
    width: $screen-xl;
  }

}

.inner--xxl {
  
  @include mq(screen-xxl) {
    width: $screen-xxl;
  }

}

.site-wrapper {
  position: relative;
  z-index: 1;
}

.main {
  position: relative;
  z-index: 1;
}

.modal-is-open {
  
  
    height: 100vh;
    overflow: hidden;
  

}