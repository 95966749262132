// Text alignment

.align-center {
  text-align: center;
}

// Background color

.bg-navy {
  background-color: $color-navy;
}

.bg-blue {
  background-color: $color-blue-dark;
}

.bg-brass {
  background-color: $color-brass;
}

.bg-marble-navy {
  background: url('img/bg-marble-navy.jpg') center center no-repeat;
  background-size: cover;
}

.bg-marble-blue {
  background: url('img/bg-marble-blue.jpg') center center no-repeat;
  background-size: cover;
}

.bg-marble-white {
  background: url('img/bg-marble-white.jpg') center center no-repeat;
  background-size: cover;
}

// Asset rendering

.video-embed {
  @include aspect-ratio(16, 9);
}

.video-embed iframe {
  width: 100%;
  height: 100%;
}

.flex-img {
  @include flex-img;
}

// Borders