.cookery-classes {
  display: grid;
  grid-gap: $padding-md;

  list-style-type: none;

    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-md) {
      grid-template-columns: repeat(3, 1fr);
    }

}

.cookery-class__toggle {
  position: relative;
  width: 100%;

  height: 25rem;

  border: none;
  overflow: hidden;

    @include mq(screen-lg) {
      height: 35rem;
    }

    &:hover {
      
      .cookery-class__info {
        background-color: $color-navy;
      }

      .cookery-class__img {
        transform: scale(1.1);
      }

    }

}

.cookery-class__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;

  transition: $transition-sm;
}

.cookery-class__info {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  padding: $padding-sm;

  background-color: rgba($color-navy, 0.75);
  transition: $transition-sm;
}

.cookery-class__info-title {
  position: relative;
  padding: 0 0 0 $padding-sm;

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      width: 2px;
      height: 100%;

      background-color: $color-brass;
    }

}

// Modal

.cookery-class-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  width: 100%;
  height: 100%;

  background-color: rgba($color-navy, 0.85);

  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;

    &.is-open {
      visibility: visible;
      opacity: 1;

      overflow: auto;
    }

    @include mq(screen-md) {
      display: grid;
      place-items: center;
    }
}

.cookery-class-modal {
  display: none;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: $padding-md;

  color: white;

  @extend .bg-marble-blue;

    &.is-visible {
      display: block;

        @include mq(screen-sm) {
          display: grid;
        }
    }

    @include mq(screen-sm) {
      grid-template-columns: 1fr 2.5fr;
      grid-gap: $padding-md;
    }

    @include mq(screen-md) {
      width: 75%;
      min-height: 0;
    }

    .std-content {
      font-size: $font-size-xxs;

        & > *+* {
          margin: $padding-sm 0 0;
        }

        p {
          font-size: $font-size-xxs;
        }

        a {
          color: white;
        }

        strong {
          color: $color-brass;
        }

    }
}

.cookery-class-modal__img {
  display: none;

    @include mq(screen-sm) {
      position: relative;
      z-index: 1;

      display: block;
      width: 100%;
      height: auto;
    }

}

.cookery-class-modal__content {
  position: relative;
  z-index: 1;
}

.close-modal {
  position: absolute;
  top: $padding-sm;
  right: $padding-sm;
  z-index: 1;

  width: 2rem;
  height: 2rem;

  background: none;

  fill: $color-brass;

    &:hover {
      fill: white;
    }

}