.home-hero {
  position: relative;
  background-color: $color-navy;

    @include mq(screen-sm) {
      height: 75vh;
    }

}

.home-hero__img {
  display: block;
  width: 100%;
  height: auto;

    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      height: 100%;
      opacity: 0.5;

      object-fit: cover;
    }

}

.home-hero__content {
  padding: $padding-lg $padding-md;

    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 75%;
      height: 100%;
    }

    @include mq(screen-lg) {
      width: $screen-sm;
    }

}

.home-hero__text {
  font-family: $oswald-light;
  font-size: calc($font-size-md * $font-mobile-scale);
  line-height: $line-height-md;
  color: white;

    @include mq(screen-lg) {
      font-size: $font-size-md;
    }

}