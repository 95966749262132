/*!
Theme Name: Chef's Counter
Theme URI: 
Author: Abbas Arezoo
Author URI: http://www.abbasarezoo.com/
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/


@import "config/fonts";
@import "config/variables";
@import "config/mixins";
@import "config/media-queries";

@import "global/reset";
@import "global/helpers";
@import "global/layout";
@import "global/spacing";
@import "global/header";
@import "global/footer";
@import "global/typography";
@import "global/buttons";
@import "global/animations";

@import "components/social-list";
@import "components/home-hero";
@import "components/std-content";
@import "components/menu-cta";
@import "components/contact-cta";
@import "components/hero";
@import "components/text-block";
@import "components/features-list";
@import "components/cookery-classes";
@import "components/contact-panel";
@import "components/form";

@import "plugins/theme-my-login";