// Button

.btn {
  display: block;
  padding: 1rem $padding-lg;

  font-family: $bebas-regular;
  font-size: $font-size-xs;
  text-decoration: none;
  letter-spacing: 0.05em;
  text-align: center;

  border: 2px solid;
}

button.btn,
input[type="submit"].btn {
  font-family: $bebas-regular;
  background: transparent;

    &:hover {
      background: rgba(white, 0.1);
    }
    
}

.btn--white {
  color: white;

    &:hover {
      background-color: rgba(white, 0.1);
    }

}

.btn--white-brass {
  color: white;

  border-color: $color-brass;

    &:hover {
      background-color: rgba(white, 0.1);
    }

}

// Button group

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-sm;

  list-style: none;

    li {
      width: 100%;

        @include mq(screen-xs) {
          width: auto;
        }
    }
}

.home-hero__content {
  
  .btn-group {
    justify-content: center;
  }

}