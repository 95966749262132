// =================================================
// STANDARD CONTENT STYLES
// =================================================

.std-content {
	width: 100%;

	& > *+* {
		margin: $padding-md 0 0;
	}
	
	// Main headings

	h2, h3, h4, h5, h6 {
		font-family: $bebas-book;
		line-height: $line-height-sm;
	}

	// Paragraphs

	p,ul,ol {

		a {
			color: black;
			text-decoration: underline;
		}

		a:hover {
			opacity: 0.75;
		}

		& > strong {
			color: black;
		}

		& > em {
			font-style: italic;
		}
					
	}

	p {
		line-height: $line-height-md;
	}

	sup {
		font-size: 75%;
	}

	ul, ol {

		li::marker {
		}

	}

	ul {
		list-style-type: disc;
		margin-left: $padding-sm;
	}

	ol {
		list-style-type: decimal;
		margin-left: $padding-sm;
		counter-reset: item;
	}

	ul ul {
		margin: 1rem 0 0 3rem;
		list-style-type: circle;
	}

	li {		
		line-height: $line-height-md;
	}

	li + li {
		margin-top: 1rem;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: $padding-lg auto;
	}

	a {
		color: inherit;
		text-decoration: underline;

			&:hover {
			}

	}

	blockquote {
	}

	address {
		font-style: normal;
	}


}

// WP overrides

.std-content {

	img.alignleft,
	img.alignright {
		width: 50%;
		margin: $padding-lg auto;

			@include mq(screen-xxs) {
				width: 20%;
				clear: both;
				margin-top: 0.5rem;
				margin-bottom: $padding-md;
			}

			@include mq(screen-lg) {
				width: 25%;
			}

	}

	img.alignleft {
		
		@include mq(screen-xxs) {
			float: left;
			margin-right: $padding-lg;
		}

	}

	img.alignright {
		
		@include mq(screen-xxs) {
			float: right;
			margin-left: $padding-lg;
		}

	}

	img.alignnone {
		clear: both;
	}

	.wp-caption {
		width: 100% !important;
		height: auto;
		margin: $padding-lg auto;


			img {
				margin: 0;
			}
			
	}

	p.wp-caption-text {
		margin: 0 auto;
		padding: 0.5rem;

		font-size: $font-size-sm;
		line-height: $line-height-md;
		text-align: center;

		// background-color: $color-grey-1;
	}

	.video-embed {
		clear: both;
		margin: $padding-lg 0;
	}

}