.pg-footer {
  display: grid;
  grid-gap: $padding-md;
  padding: 0 0 $padding-md;
  margin: 0 0 $padding-md;

  border-bottom: 1px solid $color-brass;

    @include mq(screen-lg) {
      padding: 0 0 $padding-lg;
      margin: 0 0 $padding-lg;
      
      grid-template-columns: 3fr 2fr;
    }
  
    .title {
      text-decoration: underline;
    }

}

.pg-footer__address-info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 $padding-md;

  border-bottom: 1px solid $color-brass;

    @include mq(screen-lg) {
      display: grid;
      grid-gap: $padding-lg;
      grid-template-columns: repeat(3, 1fr);
      padding: 0;

      border-bottom: none;
      border-right: 1px solid $color-brass;
    }

}

.pg-footer__logo-outer {
  width: 100%;
  margin: 0 0 $padding-md;

    @include mq(screen-lg) {
      margin: 0;
    }

}

.pg-footer__logo {
  display: block;
  width: 10rem;
  height: auto;
  margin: 0 auto;

    @include mq(screen-lg) {
      margin: 0;
    }

}

.pg-footer__address,
.pg-footer__hours {
  width: 100%;

  text-align: center;

    @include mq(screen-xs) {
      width: 50%;
      padding: 0 $padding-md;
    }

    @include mq(screen-lg) {
      width: auto;
      padding: 0;

      text-align: left;
    }

}

.pg-footer__address {
  margin: 0 0 $padding-md;

    @include mq(screen-xs) {
      margin: 0;
    }

}

.pg-footer__text,
.pg-footer__nav-list {
  font-family: $oswald-light;
  font-size: $font-size-xxs;
}

.pg-footer__nav-outer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

    @include mq(screen-lg) {
      display: grid;
      grid-gap: $padding-lg;
      grid-template-columns: repeat(2, 1fr);
    }

}

.pg-footer__nav,
.pg-footer__contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  text-align: center;

    @include mq(screen-xs) {
      width: 50%;
      padding: 0 $padding-md;
    }

    @include mq(screen-lg) {
      align-items: flex-start;
      width: auto;
      padding: 0;

      text-align: left;
    }

}

.pg-footer__nav {
  margin: 0 0 $padding-md;

    @include mq(screen-xs) {
      margin: 0;
    }

}

.pg-footer__nav-list {
  display: grid;
  grid-gap: 0.5rem;

  text-align: center;
  list-style-type: none;

    @include mq(screen-lg) {
      text-align: left;
    }

    a {
      color: $color-navy;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

}

.signoff {
  font-family: $oswald-light;
  font-size: $font-size-xxs;
  text-align: center;

    @include mq(screen-xs) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

}

.signoff__nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style-type: none;

    @include mq(screen-xs) {
      flex-direction: row;
      justify-content: flex-end;
      gap: $padding-md;
    }

    a {
      color: $color-navy;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

}
