.pg-header-outer {
  position: relative;
  z-index: 2;
  padding: $padding-sm 0;

    @include mq(screen-sm) {
      padding: 0;
    }

}

// Contact bar

.contact-bar {
  display: flex;
  justify-content: center;

    @include mq(screen-sm) {
      justify-content: space-between;
    }
  
    .social-list {
      display: none;

        @include mq(screen-sm) {
          display: flex;
        }

    }

}

.contact-bar__list {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: $font-size-xxs;
  list-style: none;

    li {
      position: relative;
    }

    a {
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    li + li {
      margin: 0 0 0 1rem;
      padding: 0 0 0 1rem;

        &:before {
          content: "";

          position: absolute;
          top: 0;
          left: 0;

          width: 1px;
          height: 100%;

          background-color: white;
        }

    }

}

// Page header

.pg-header {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 $padding-sm;

  background: white;

    @include mq(screen-sm) {      
      justify-content: center;
      height: 9.25rem;
      padding: 0;
    }

    @include mq(screen-lg) {
      height: 12.5rem;
    }

}

.pg-header__logo {
  position: relative;
  display: block;
  width: 8rem;
  height: 8rem;
  margin: 0 0 $padding-md;

  background: url('img/bg-marble-navy.jpg') center center no-repeat;

    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      width: 12rem;
      height: 12rem;
    }

    @include mq(screen-lg) {
      width: 16rem;
      height: 16rem;
    }

    svg {
      position: absolute;
      bottom: 1rem;
      left: 0;
      z-index: 1;

      width: 100%;
      height: auto;
    }

}

.main-nav {
  display: flex;
  flex-direction: column;
  gap: $padding-sm;
  
  @extend .inner;
  @extend .inner--lg;

    @include mq(screen-sm) {
      flex-direction: row;
      justify-content: center;

      width: 100%;
    }
}

.main-nav__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $padding-sm;

  list-style: none;

  font-family: $bebas-regular;
  font-size: $font-size-xs;

    @include mq(screen-sm) {
      position: relative;
      z-index: 1;

      gap: $padding-md;

      width: 50%;
    }

    @include mq(screen-lg) {
      gap: $padding-lg;

      font-size: $font-size-sm;
    }

    &:first-child {
      
      @include mq(screen-sm) {
        justify-content: flex-end;
        padding: 0 8rem 0 0;
      }

      @include mq(screen-lg) {
        padding: 0 12rem 0 0;
      }

    }

    &:last-child {
      
      @include mq(screen-sm) {
        justify-content: flex-start;
        padding: 0 0 0 8rem;
      }

      @include mq(screen-lg) {
        padding: 0 0 0 12rem;
      }

    }

    a {
      color: $color-blue-dark;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

}