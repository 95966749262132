.menu-cta {
  display: flex;
  gap: $padding-md;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @extend .ptb-lg;

    @include mq(screen-lg) {
      gap: $padding-lg;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      text-align: left;
    }
    
    .btn {
      width: 100%;

      @include mq(screen-xs) {
        width: auto;
      }
      
      @include mq(screen-lg) {
        flex-shrink: 0;
      }

    }

}